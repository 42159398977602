'use client';
import { GET_USER_BY_ID } from '@/services/gql/user';
import {
  isBusiness,
  isCompany,
  isEmployee,
  isEmployer,
  isEndUser,
} from '@/utils/functions/flag';
import { useQuery } from '@apollo/client';
import {
  SessionProvider as AuthSessionProvider,
  signOut,
  useSession,
} from 'next-auth/react';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getAuthStorage,
  removeAuthStorage,
  setAuthStorage,
} from '../authStorage';
import { usePathname, useRouter } from 'next/navigation';
import { TAG_KEY } from '@/utils/constants/localStorageKeys';

export const AuthContext = createContext<any>({});

interface IProfile {
  profile: any;
}

interface IAuth {
  user: any;
  isLoggedIn: boolean;
  loading: boolean;
  isEmployee: boolean;
  isEmployer: boolean;
  isCompany: boolean;
  isBusiness: boolean;
  isEndUser: boolean;
  role: string;
  refetch: () => Promise<void>;
  logout: () => Promise<void>;
}

export function SessionProvider({ children }: { children: React.ReactNode }) {
  return (
    <AuthSessionProvider refetchInterval={10 * 60}>
      {children}
    </AuthSessionProvider>
  );
}

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { push } = useRouter();
  const pathName = usePathname();

  const { data: session, status } = useSession();
  
  const [user, setUser] = useState<IProfile>({ profile: null });

  const { refetch: getProfile } = useQuery(GET_USER_BY_ID, {
    variables: {
      id: session?.user?.id,
    },
    notifyOnNetworkStatusChange: true,
    skip: !session?.accessToken || !session?.user?.id,
    onCompleted: (res: any) => {
      setAuthStorage(session?.accessToken ?? '');
      setUser({ profile: res.getUser });
    },
    onError: async (err: any) => {
      await signOut();
    },
  });

  const logout = async () => {
    removeAuthStorage();
    await signOut({
      redirect: true,
      callbackUrl: `${window.location.origin}?status=unauthenticated`,
    });
  };

  const onSessionTimeout = () => {
    setUser({ profile: false });
    const token = getAuthStorage();
    if (token) {
      removeAuthStorage();
    }
  };

  useEffect(() => {
    if (session === null) {
      onSessionTimeout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    const seenTag = localStorage.getItem(TAG_KEY);
    if (isRoleEmployee && user?.profile?.tags?.length === 0 && !seenTag) {
      localStorage.setItem(TAG_KEY, 'seen');
      push('/tag');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile, pathName]);

  const role = useMemo(() => {
    if (session?.user?.role) {
      return (session?.user?.role || '').toUpperCase();
    }
    return (user?.profile?.role?.name || '').toUpperCase();
  }, [session?.user?.role, user?.profile?.role?.name]);

  const isRoleEmployee = useMemo(() => {
    return isEmployee(role);
  }, [role]);

  const isRoleEmployer = useMemo(() => {
    return isEmployer(role);
  }, [role]);

  const isRoleCompany = useMemo(() => {
    return isCompany(role);
  }, [role]);

  const isRoleBusiness = useMemo(() => {
    return isBusiness(role);
  }, [role]);

  const isRoleEndUser = useMemo(() => {
    return isEndUser(role);
  }, [role]);

  const store = {
    user: user?.profile,
    isLoggedIn: !!getAuthStorage(),
    loading: status === 'loading',
    isEmployee: isRoleEmployee,
    isEmployer: isRoleEmployer,
    isCompany: isRoleCompany,
    isBusiness: isRoleBusiness,
    isEndUser: isRoleEndUser,
    role: role,
    refetch: getProfile,
    logout,
  };

  return <AuthContext.Provider value={store}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuth => useContext(AuthContext);
