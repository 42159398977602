'use client';
import { io } from 'socket.io-client';

const SOCKET_URL = process.env.NEXT_PUBLIC_BASE_SOCKET_URL ?? '';

const socket = io(SOCKET_URL, {
  path: '/socket.io',
  // withCredentials: true,
  autoConnect: false,
});

export default socket;
