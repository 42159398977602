import type { ThemeConfig } from 'antd';
import { defaultFormTheme } from './constants';

const theme: ThemeConfig = {
  token: {
    fontSize: 16,
    fontFamily: `var(--font-primary), var(--font-secondary)`,
    colorPrimary: '#0278D6',
    colorText: '#3B4759',
    colorTextDescription: '#82878E',
    borderRadius: 12,
    colorPrimaryBorder: '#0278D6',
    colorBorder: '#E9E9E9',
    colorBorderSecondary: '#f1f1f1',
    colorBgContainerDisabled: '#F0F0F0',
    controlHeight: 36,
  },
  components: {
    Grid: {
      screenXSMin: 428,
      screenXSMax: 639,
      screenSMMin: 640,
      screenSMMax: 767,
      screenMDMin: 768,
      screenMDMax: 1023,
      screenLGMin: 1024,
      screenLGMax: 1279,
      screenXLMin: 1280,
      screenXLMax: 1511,
      screenXXL: 1512,
    },
    Input: {
      ...defaultFormTheme,
    },
    Select: {
      ...defaultFormTheme,
      borderRadius: 8,
    },
    DatePicker: {
      ...defaultFormTheme,
      borderRadius: 8,
    },
    Button: {
      controlHeight: 42,
    },
    Radio: {
      colorBorder: '#40506a',
      colorPrimary: '#40506a',
      dotSize: 12,
    },
    Collapse: {
      borderRadiusLG: 8,
    },
    Checkbox: {
      colorBorder: '#40506a',
      colorPrimary: '#40506a',
      colorPrimaryHover: '#40506a',
      borderRadiusSM: 4,
    },
    Menu: {
      activeBarHeight: 5,
      itemColor: '#3B4759',
    },
    Switch: {
      handleSize: 26,
      trackHeight: 30,
      trackMinWidth: 60,
      colorPrimary: 'rgb(58, 171, 130) !important',
      colorPrimaryHover: 'rgba(58, 171, 130, 0.8)  !important',
      colorTextQuaternary: 'rgb(203, 213, 227) !important',
      colorTextTertiary: 'rgba(0, 0, 0, 0.45) !important',
    },
    Tabs: {
      verticalItemMargin: '12px 0 0 0',
    },
  },
};

export default theme;
