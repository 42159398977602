import { gql } from '@apollo/client';

export const GET_USER_BY_ID = gql`
  query GET_USER($id: ID!) {
    getUser(id: $id) {
      id
      username
      email
      selfDescription
      confirmed
      isVerify
      friendFlag
      role {
        id
        name
      }
      IDCard
      license
      firstName
      lastName
      isDeleted
      rating
      occupation
      profession_one {
        id
        title
        licenseRequired
      }
      profession_two {
        id
        title
        licenseRequired
      }
      profession_three {
        id
        title
        licenseRequired
      }
      license
      avatar {
        id
        url
      }
      company
      rating
      education
      mobileNumber
      expectSalary
      gender
      birthday
      juristicId
      tags {
        id
        title
      }
      banner {
        id
        url
      }
      favorite_jobs {
        id
      }
      applications(where: { status_ne: "CANCELLED" }) {
        id
        status
        job {
          id
        }
      }
      province {
        name_th
        name_en
        province_id
      }
      district {
        name_th
        name_en
        district_id
      }
      sub_district {
        name_th
        name_en
        sub_district_id
      }
      address
      IDCardImg {
        url
      }
      educationCertificate {
        url
      }
      professionalLicense {
        url
      }
      companyCertificate {
        url
      }
      issuedCard {
        url
      }
      verificationStatus
      verificationDescription
      verificationSubmitDate
      blocked
      admin_room {
        id
      }
      isSponsor
      sponsoredStart
      sponsoredEnd
      yearsOfWork
      monthsOfWork
      portfolioLink
      resume {
        url
        name
      }
      contactInfo
      loginMethod
      workExperience
    }
  }
`;

export const UPLOADER_PROFILE = gql`
  mutation UPLOADER_PROFILE($userId: ID, $field: String!, $file: Upload!) {
    uploaderProfile(input: { userId: $userId, field: $field, file: $file }) {
      data
      status
    }
  }
`;

export const UPDATE_VERIFY = gql`
  mutation UPDATE_VERIFY(
    $endUserId: ID
    $address: String
    $province: ID!
    $district: ID!
    $sub_district: ID!
    $IDCardImg: Upload
    $issuedCard: Upload
    $companyCertificate: Upload
    $professionalLicense: Upload
  ) {
    updateVerify(
      input: {
        endUserId: $endUserId
        IDCardImg: $IDCardImg
        issuedCard: $issuedCard
        companyCertificate: $companyCertificate
        professionalLicense: $professionalLicense
        address: $address
        province: $province
        district: $district
        sub_district: $sub_district
      }
    ) {
      data
      message
      status
    }
  }
`;

export const SET_LINE_NOTIFICATION = gql`
  mutation SET_LINE_NOTIFICATION(
    $userID: ID!
    $lineID: String
    $friendFlag: Boolean
  ) {
    setLineNotification(
      userID: $userID
      lineID: $lineID
      friendFlag: $friendFlag
    ) {
      status
      message
      data
    }
  }
`;

export const UPDATE_USER_TAGS = gql`
  mutation UPDATE_USER_TAGS($id: ID!, $tags: [ID]!) {
    updateUser(input: { where: { id: $id }, data: { tags: $tags } }) {
      user {
        id
        firstName
        tags {
          id
          title
        }
      }
    }
  }
`;
