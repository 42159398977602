'use client';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { memo } from 'react';

const ProgressRoute = () => {
  return (
    <ProgressBar
      height="4px"
      color="#0278D6"
      options={{ showSpinner: false }}
      shallowRouting
    />
  );
};

export default memo(ProgressRoute);
