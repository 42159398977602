'use client';

import { ConfigProvider } from 'antd';
import React from 'react';
import StyledComponentsRegistry from './AntdRegistry';

import theme from '@/theme/themeConfig';

const AntdProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ConfigProvider prefixCls="medimatch" theme={theme}>
      <StyledComponentsRegistry>{children}</StyledComponentsRegistry>
    </ConfigProvider>
  );
};

export default AntdProvider;
