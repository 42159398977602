'use client';
import Button from '@/components/atoms/Button';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { memo } from 'react';

const MainLayout = dynamic(() => import('@/components/layouts/MainLayout'), {
  ssr: false,
});

const NotFound = ({ reload }: { reload?: boolean }) => {
  const { replace, refresh } = useRouter();
  return (
    <MainLayout
      className="!bg-white"
      contentClassName="bg-white"
      headers={{ rounded: true, shadow: 'primary' }}
    >
      <div className="flex flex-col justify-center items-center p-25 content-body">
        <Image
          className="my-[90px]"
          src="/assets/images/404.svg"
          width={452}
          height={320}
          alt="not-found"
        />
        <h6 className="text-gray-900 md:text-2xl font-medium text-center">{`The internet's all good, but it seems what you're after is on a mini-vacation.`}</h6>
        <p className="text-gray-400 text-base mt-4">
          Our team is tracking it down!
        </p>
        <Button
          className="text-xs md:text-base mt-10"
          ghost
          onClick={() => (reload ? refresh() : replace(`/`))}
        >
          กลับไปหน้าแรก
        </Button>
      </div>
    </MainLayout>
  );
};

export default memo(NotFound);
