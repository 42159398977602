import { getAuthStorage, removeAuthStorage } from '@/lib/authStorage';
import { RESPONSE_CODE } from '@/utils/constants/responseMessage';
import { isServer } from '@/utils/functions/flag';
import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  NextSSRApolloClient,
  NextSSRInMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';
import { createUploadLink } from 'apollo-upload-client';
import { signOut } from 'next-auth/react';

const authLink = setContext(async (_, { headers }) => {
  const token = getAuthStorage();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const errors of graphQLErrors) {
      const { message, locations, path } = errors;
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );

      if (message === RESPONSE_CODE.DEACTIVE_ACCOUNT) {
        removeAuthStorage();
        signOut({
          redirect: true,
          callbackUrl: `${window.location.origin}?status=deactive`,
        });
        return;
      }

      if (message === RESPONSE_CODE.INVALID_TOKEN) {
        removeAuthStorage();
        signOut({
          redirect: true,
          callbackUrl: `${window.location.origin}?status=invalid_token`,
        });
        return;
      }
    }
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

export function makeClient() {
  const httpLink = createUploadLink({
    uri: isServer ? process.env.GRAPHQL_API : '/api/graphql',
    credentials: 'include',
  }) as unknown;

  const serverLink = new SSRMultipartLink({
    stripDefer: true,
  });

  const links = ApolloLink.from([errorLink, authLink, httpLink as ApolloLink]);
  const linksServer = ApolloLink.from([serverLink, links]);

  return new NextSSRApolloClient({
    cache: new NextSSRInMemoryCache(),
    ssrMode: isServer,
    link: isServer ? linksServer : links,
  });
}
