export const isServer = typeof window === 'undefined';

export const isEmployee = (role: string): boolean => {
  return ['EMPLOYEE'].includes((role || '').toUpperCase());
};

export const isEmployer = (role: string): boolean => {
  return ['EMPLOYER'].includes((role || '').toUpperCase());
};

export const isCompany = (role: string): boolean => {
  return ['COMPANY'].includes((role || '').toUpperCase());
};

export const isBusiness = (role: string): boolean => {
  return ['EMPLOYER', 'COMPANY'].includes((role || '').toUpperCase());
};

export const isEndUser = (role: string): boolean => {
  return ['EMPLOYEE', 'EMPLOYER', 'COMPANY'].includes(
    (role || '').toUpperCase(),
  );
};

export const isVerify = (status: string) => {
  return ['APPROVED', 'LICENSE_APPROVED'].includes(status || '');
};

export const isWatingReview = (status: string) => {
  return ['WAITING_REVIEW', 'WAITING_LICENSE_REVIEW'].includes(status || '');
};

export const isNotApproved = (status: string) => {
  return ['NOT_APPROVED', 'LICENSE_NOT_APPROVED'].includes(status || '');
};
