'use client';
import clsx from 'clsx';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import { memo } from 'react';

import styles from './index.module.scss';

const Button = ({
  className,
  children,
  type = 'default',
  size,
  danger,
  ghost,
  disabled,
  noStyle = false,
  shadow,
  suffixIcon,
  ...props
}: ButtonProps) => {
  return (
    <AntdButton
      ghost
      disabled={disabled}
      className={clsx({
        [`${className}`]: className,
        [styles.btn]: noStyle === false,
        [styles.btnShadow]: shadow,
        [styles.btnLarge]: size === 'large',
        [styles.btnMiddle]: size === 'middle',
        [styles.btnGhost]: ghost,
        [styles.btnDefault]: type === 'default' && !ghost && !noStyle,
        [styles.btnPrimary]: type === 'primary',
        [styles.btnDanger]: danger,
        [styles.btnDisabled]: disabled,
        [styles.btnLink]: type === 'link',
        [styles.btnCustom]: noStyle === true,
      })}
      {...props}
    >
      {children}
      {suffixIcon && suffixIcon}
    </AntdButton>
  );
};

export type ButtonProps = AntdButtonProps & {
  className?: string;
  children?: React.ReactNode;
  noStyle?: boolean;
  shadow?: boolean;
  suffixIcon?: React.ReactNode;
};

export default memo(Button);
