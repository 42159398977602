export const RESPONSE_MESSAGE: IResponseMessage = {
  SUCCESS_TITLE: 'สำเร็จ!',
  NOT_SUCCESS_TITLE: 'ไม่สำเร็จ!',
  WARNING_TITLE: 'เกิดข้อผิดพลาดบางอย่าง',
  ERROR_TITLE: 'ขออภัย!',
  ERROR: 'เกิดข้อผิดพลาดบางอย่าง โปรดลองอีกครั้งในภายหลัง',
  INVALID_EMAIL_OR_PASSWORD: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่ภายหลัง',
  UNAUTHORIZED: 'โปรดตรวจสอบสิทธิ์การเข้าใช้งานของคุณสำหรับการเข้าสู่ระบบ',
  CONFIRM_EMAIL:
    'โปรดตรวจสอบอีเมลของคุณและใช้ลิงก์ที่แนบมาเพื่อยืนยันการลงทะเบียน',
  NO_PERMISSION: 'ผู้ใช้งานให้สิทธิ์การเข้าถึงระบบไม่ครบถ้วน',
  DEACTIVE: 'บัญชีของคุณโดนระงับ กรุณาติดต่อผู้ดูแล',
  SESSION_TIMEOUT: 'เซสชันหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
  DEFAULT_SUCCESS: 'ทำรายการสำเร็จ',
  LOGGED_SUCCESS: 'เข้าสู่ระบบเรียบร้อยแล้ว',
  LOGOUT_SUCCESS: 'ออกจากระบบเรียบร้อยแล้ว',
  FAV_SUCCESS: 'เก็บงานนี้ไว้ในงานโปรดของคุณแล้ว',
  UNFAV_SUCCESS: 'ลบงานนี้ออกจากงานโปรดของคุณแล้ว',
  COPY_TITLE: 'คัดลอกไปยังคลิปบอร์ดแล้ว',
  COPIED: 'วางที่ไหนก็ได้ตามที่คุณต้องการ',
  CONFIRM_EMAIL_SUCCESS: 'ยืนยันอีเมลเรียบร้อยแล้ว',
  APPLY_JOB_SUCCESS_TITLE: 'สมัครงานสำเร็จ',
  APPLY_JOB_SUCCESS:
    'กรุณารอสักครู่ ระบบกำลังพาคุณไปหน้าข้อความเพื่อติดต่อทางผู้ว่าจ้าง',
  APPLY_JOB_EMAIL_SUCCESS:
    'กรุณารอการติดต่อกลับจากทางผู้ว่าจ้างผ่านทางอีเมลที่ระบุไว้',
  AVATAR_UPDATE_SUCCESSS: 'อัปเดตรูปโปรไฟล์สำเร็จ',
  BANNER_UPDATE_SUCCESSS: 'อัปเดตแบนเนอร์สำเร็จ',
  PROFILE_UPDATE_SUCEESS: 'อัปเดตข้อมูลโปรไฟล์สำเร็จ',
  REQUEST_RESET_PARSSWORD:
    'ระบบได้ส่งอีเมลสำหรับรีเซ็ตรหัสให้คุณแล้ว หากคุณไม่ได้รับอีเมลยืนยันจากเรา กรุณาเช็คโฟลเดอร์สแปมหรืออีเมลขยะของคุณ',
  RESET_PASSWORD_SUCCESS:
    'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว กรุณารอสักครู่ ระบบกำลังพาคุณไปหน้าเข้าสู่ระบบ',
  CHANGE_PASSWORD_SUCCESS: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว',
  EXPIRED_LINK: 'ลิงก์หมดอายุหรือไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
  CREATE_JOB_SUCCESS: 'การ์ดงานถูกสร้างเรียบร้อยแล้ว',
  EDIT_JOB_SUCCESS: 'แก้ไขการ์ดงานเรียบร้อยแล้ว',
  UPDATE_TAG_SUCCESS: 'แก้ไขแท็กเรียบร้อยแล้ว',
  REGISTER_SUCCESS: 'ลงทะเบียนสำเร็จ',
  FILE_TYPE_ERROR: `ประเภทไฟล์ไม่ถูกต้อง กรุณาอัปโหลดใหม่\nอีกครั้ง`,
  REPORT_SUCCESS: `รายงานผู้ใช้นี้เรียบร้อยแล้ว`,
  VERIFY_IDENTITY_SUCCESS: `ยืนยันตัวตนสำเร็จเรียบร้อยแล้ว`,
  EMAIL_ALREADY_USED: `มีอีเมล์นี้ในระบบแล้ว กรุณาเข้าสู่ระบบผ่านช่องทางที่สมัคร`,
  UNSEND_MESSAGE_SUCCESS: 'ยกเลิกการส่งข้อความแล้ว',
  JOBSTATUS_UPDATED_SUCCESS: 'อัปเดตสถานะงานสำเร็จ',
  DELETE_JOB_SUCCESS: 'ลบประกาศงานสำเร็จ',
};

export const RESPONSE_CODE: IResponseCode = {
  ERROR: 'ERROR',
  LOGIN: {
    ['Auth.form.error.invalid']: 'INVALID_EMAIL_OR_PASSWORD',
    ['Auth.form.error.blocked']: 'DEACTIVE',
    400: 'INVALID_EMAIL_OR_PASSWORD',
    401: 'UNAUTHORIZED',
    403: 'CONFIRM_EMAIL',
    428: 'NO_PERMISSION',
  },
  RESET_PASSWORD: {
    406: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเดิมของคุณ',
    400: `${RESPONSE_MESSAGE.EXPIRED_LINK}`,
    408: `${RESPONSE_MESSAGE.EXPIRED_LINK}`,
  },
  CHANGE_PASSWORD: {
    400: 'รหัสผ่านเดิมไม่ถูกต้อง',
    401: 'โปรดตรวจสอบสิทธิ์การเข้าใช้งานของคุณ',
    404: 'ผู้ใช้งานไม่ถูกต้อง',
    406: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเดิมของคุณ',
    409: 'ผู้ใช้งานเข้าสู่ระบบด้วยช่องทางอื่น',
    422: 'โปรดกรอกข้อมูลรหัสผ่านและยืนยันรหัสผ่านให้ตรงกัน',
  },
  SETUP_LINE_NOTIFICATION: {
    409: 'มีบัญชี LINE นี้อยู่ในระบบแล้ว',
    428: 'ผู้ใช้งานให้สิทธิ์การเข้าถึงระบบไม่ครบถ้วน',
  },
  UPDATE_TAG: {
    500: 'แก้ไขแท็กไม่สำเร็จ กรุณาลองใหม่ภายหลัง',
    409: 'ชื่อแท็กนี้มีอยู่ในระบบแล้ว',
  },
  JOB_CONFIRMATION: {
    401: 'ผู้ใช้งานไม่สามารถดำเนินการนี้',
    404: 'ไม่พบข้อมูลการสมัครงาน',
    405: 'กรุณายืนยันตนก่อนส่งใบรับงาน',
    409: 'ไม่สามารถดำเนินการได้',
    410: 'ตำแหน่งงานเต็ม',
    428: 'ขณะนี้บัญชีของ ผู้ใช้งานนี้ ได้ถูกยกเลิกการยืนยันตัวตน กรุณารอ ผู้ใช้งานนี้ ยืนยันตัวตนใหม่อีกครั้ง',
  },
  DEACTIVE_ACCOUNT: `Your account has been blocked by the administrator.`,
  INVALID_TOKEN: `Invalid token.`,
  CHANGE_PASSWORD_EMPLOYEE: {
    406: 'รหัสผ่านใหม่ต้องไม่ซ้ำกับรหัสผ่านเดิมของคุณ',
    400: `${RESPONSE_MESSAGE.EXPIRED_LINK}`,
    408: `${RESPONSE_MESSAGE.EXPIRED_LINK}`,
  }
};

export interface IResponseMessage {
  SUCCESS_TITLE: string;
  NOT_SUCCESS_TITLE: string;
  WARNING_TITLE: string;
  ERROR_TITLE: string;
  ERROR: string;
  INVALID_EMAIL_OR_PASSWORD: string;
  UNAUTHORIZED: string;
  CONFIRM_EMAIL: string;
  NO_PERMISSION: string;
  DEFAULT_SUCCESS: string;
  LOGGED_SUCCESS: string;
  LOGOUT_SUCCESS: string;
  FAV_SUCCESS: string;
  UNFAV_SUCCESS: string;
  COPY_TITLE: string;
  COPIED: string;
  CONFIRM_EMAIL_SUCCESS: string;
  APPLY_JOB_SUCCESS_TITLE: string;
  APPLY_JOB_SUCCESS: string;
  APPLY_JOB_EMAIL_SUCCESS: string;
  AVATAR_UPDATE_SUCCESSS: string;
  BANNER_UPDATE_SUCCESSS: string;
  PROFILE_UPDATE_SUCEESS: string;
  REQUEST_RESET_PARSSWORD: string;
  RESET_PASSWORD_SUCCESS: string;
  CHANGE_PASSWORD_SUCCESS: string;
  EXPIRED_LINK: string;
  CREATE_JOB_SUCCESS: string;
  EDIT_JOB_SUCCESS: string;
  UPDATE_TAG_SUCCESS: string;
  REGISTER_SUCCESS: string;
  FILE_TYPE_ERROR: string;
  REPORT_SUCCESS: string;
  DEACTIVE: string;
  SESSION_TIMEOUT: string;
  VERIFY_IDENTITY_SUCCESS: string;
  EMAIL_ALREADY_USED: string;
  UNSEND_MESSAGE_SUCCESS: string;
  JOBSTATUS_UPDATED_SUCCESS: string;
  DELETE_JOB_SUCCESS: string;
}

export interface IResponseCode {
  ERROR: string;
  LOGIN: any;
  RESET_PASSWORD: any;
  CHANGE_PASSWORD: any;
  SETUP_LINE_NOTIFICATION: any;
  UPDATE_TAG: any;
  JOB_CONFIRMATION: any;
  DEACTIVE_ACCOUNT: string;
  INVALID_TOKEN: string;
  CHANGE_PASSWORD_EMPLOYEE: any;
}
