import { PREVIEW_KEY, TAG_KEY } from '@/utils/constants/localStorageKeys';
import { isServer } from '@/utils/functions/flag';

export const TOKEN_KEY = 'ErV8D';

export const setAuthStorage = (token: string) => {
  if (token) localStorage.setItem(TOKEN_KEY, token);
};

export const getAuthStorage = (): string => {
  if (isServer) return '';
  const token = localStorage.getItem(TOKEN_KEY) as string;
  return token;
};

export const removeAuthStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(TAG_KEY);
  localStorage.removeItem(PREVIEW_KEY);
};
