'use client';
import Script from 'next/script';

const Clarity = () => {
  const CLARITY_KEY = `${process.env.NEXT_PUBLIC_CLARITY_KEY}`;
  return (
    <>
      {CLARITY_KEY && (
        <Script id="ms-clarity" strategy="afterInteractive">
          {` (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", '${CLARITY_KEY}');`}
        </Script>
      )}
    </>
  );
};

export default Clarity;
