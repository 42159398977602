'use client';
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support/ssr';
import React from 'react';
import { makeClient } from './client';

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={makeClient}>
      {children}
    </ApolloNextAppProvider>
  );
}
