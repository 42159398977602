'use client';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { Socket } from 'socket.io-client';
import socket from '../socket';
import { useAuth } from './useAuth';

export const SocketContext = createContext<any>({});

export enum SOCKET_STATE {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
}

export enum SOCKET_CHANNEL {
  NEW_NOTIFICATION = 'newNotification',
}

export const SocketProvider = ({ children }: PropsWithChildren) => {
  const socketRef = useRef<Socket | null>(null);
  const { isLoggedIn, user, refetch, logout } = useAuth();

  useEffect(() => {
    if (!isLoggedIn) return;
    if (socketRef.current === null) {
      socket.connect();
      socketRef.current = socket;
    }

    socketRef.current?.on('connect', () => {
      console.log('socket connect');
    });

    socketRef.current?.on('connected', () => {
      console.log('socket connected');
    });

    socketRef.current?.on('disconnect', () => {
      console.log('socket disconnect');
    });

    socketRef.current?.on('forceLogout', () => {
      console.log('socket forceLogout');
      socketRef.current?.disconnect();
      logout();
    });

    return () => {
      if (socketRef.current) {
        socketRef.current?.disconnect();
      }
    };
  }, [isLoggedIn]);

  useEffect(() => {
    if (socketRef?.current?.connected && user?.id) {
      socketRef.current.emit('online', {
        id: user.id,
      });
    }

    socketRef.current?.on('connected', () => {
      if (user?.id) {
        socketRef.current?.emit('online', {
          id: user.id,
        });
      }
    });

    socketRef.current?.on('verifyUpdate', () => {
      refetch();
    });
  }, [socketRef, user]);

  const store = {
    socket: socketRef.current,
  };

  return (
    <SocketContext.Provider value={store}>{children}</SocketContext.Provider>
  );
};

interface ISocket {
  socket: Socket;
}

export const useSocket = (): ISocket => useContext(SocketContext);
