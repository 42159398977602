import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.1_@babel+core@7.23.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.69.5/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.1_@babel+core@7.23.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.69.5/node_modules/next/font/google/target.css?{\"path\":\"src/utils/constants/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"variable\":\"--font-secondary\",\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.0.1_@babel+core@7.23.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.69.5/node_modules/next/font/google/target.css?{\"path\":\"src/utils/constants/fonts.ts\",\"import\":\"Kanit\",\"arguments\":[{\"variable\":\"--font-primary\",\"subsets\":[\"latin\",\"thai\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\"}],\"variableName\":\"kanit\"}");
import(/* webpackMode: "eager" */ "/app/src/components/atoms/ProgressRoute/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/shared/Analytics/Clarity.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/shared/ErrorBoundary/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/antd/AntdProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/hooks/useAuth.tsx");
import(/* webpackMode: "eager" */ "/app/src/lib/hooks/useSocket.tsx");
import(/* webpackMode: "eager" */ "/app/src/services/gql/ApolloWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/globals.scss")